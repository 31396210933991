import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CalendarGreydownArrow, CalendarGreyUpArrow } from "../../icons";
import Switch from "@mui/material/Switch";
import { format, isValid, isSameDay as isSameDayFns } from "date-fns";

const SingleDaySelectCalendar = ({
  onDateSelect,
  toggleDatePicker,
  selectedDates,
  onReset,
  isRangeMode: initialIsRangeMode,
  setIsRangeMode: setinitialIsRangeMode,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [internalSelectedDates, setInternalSelectedDates] = useState([]);
  const [isRangeMode, setIsRangeMode] = useState(initialIsRangeMode);

  useEffect(() => {
    if (selectedDates && selectedDates.length > 0) {
      const validDates = selectedDates.filter(
        (date) => date instanceof Date && isValid(date)
      );
      setCurrentDate(validDates[0] || new Date());
      setInternalSelectedDates(validDates);
    } else {
      setCurrentDate(new Date());
      setInternalSelectedDates([]);
    }
  }, [selectedDates, initialIsRangeMode]);

  const isSameDay = (date1, date2) => {
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) return false;
    return isSameDayFns(date1, date2);
  };

  const header = () => (
    <div className="flex items-center justify-between p-4">
      <div className="flex items-center space-x-4">
        <button className="text-gray-600 text-xl" onClick={prevMonth}>
          <FaChevronLeft size={15} color="#333B48" />
        </button>
        <span className="text-lg font-bold">{format(currentDate, "MMMM")}</span>
        <button
          className="text-gray-600 text-xl"
          onClick={nextMonth}
          disabled={isCurrentMonthOrFuture()}
        >
          <FaChevronRight
            color={isCurrentMonthOrFuture() ? "#A1AEBF" : "#333B48"}
            size={15}
          />
        </button>
      </div>
      <div className="flex items-center space-x-2">
        <span className="text-base font-medium">
          {currentDate.getFullYear()}
        </span>
        <div className="flex flex-col gap-3">
          <button
            className="text-gray-600 text-xs"
            onClick={nextYear}
            disabled={isCurrentYearOrFuture()}
          >
            <CalendarGreyUpArrow
              color={isCurrentYearOrFuture() ? "#A1AEBF" : "#333B48"}
            />
          </button>
          <button className="text-gray-600 text-xs" onClick={prevYear}>
            <CalendarGreydownArrow />
          </button>
        </div>
      </div>
    </div>
  );

  const daysOfWeek = () => {
    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return (
      <div className="grid grid-cols-7 gap-1 mb-2 px-2">
        {days.map((day) => (
          <div
            key={day}
            className="text-center font-semibold text-sm text-gray-600"
          >
            {day}
          </div>
        ))}
      </div>
    );
  };

  const cells = () => {
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    const startingDayOfWeek = firstDayOfMonth.getDay() || 7; // 1-7 (Monday-Sunday)
    const daysInMonth = lastDayOfMonth.getDate();

    const rows = [];
    let days = [];

    // Add empty cells for days before the first of the month
    for (let i = 1; i < startingDayOfWeek; i++) {
      days.push(<div key={`empty-${i}`} className="p-0 text-center" />);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );
      const isToday = isSameDay(date, new Date());
      const isSelected = internalSelectedDates.some((d) => isSameDay(d, date));
      const isFuture = date > new Date();
      const isInRange =
        isRangeMode &&
        internalSelectedDates.length === 2 &&
        date >= internalSelectedDates[0] &&
        date <= internalSelectedDates[1];
      const isRangeStart =
        isRangeMode &&
        internalSelectedDates[0] &&
        isSameDay(date, internalSelectedDates[0]);
      const isRangeEnd =
        isRangeMode &&
        internalSelectedDates[1] &&
        isSameDay(date, internalSelectedDates[1]);

      const cellClassName = `formated-date2 flex justify-center items-center ${
        isSelected ? "text-white" : "text-[#515f6f]"
      } ${isFuture ? "cursor-not-allowed text-gray-300" : "cursor-pointer"} ${
        isSelected ? "bg-orange-500" : ""
      } ${
        isToday && internalSelectedDates.length === 0
          ? "bg-orange-500 text-white"
          : ""
      } ${isInRange ? "bg-orange-200" : ""} ${
        isRangeStart ? "rounded-l-full" : ""
      } ${isRangeEnd ? "rounded-r-full" : ""} ${
        isSelected && !isRangeMode ? "rounded-full" : ""
      }`;

      const cell = (
        <div
          key={day}
          className={cellClassName}
          onClick={() => !isFuture && handleDateClick(date)}
        >
          {day}
        </div>
      );
      days.push(cell);

      if (days.length === 7 || day === daysInMonth) {
        rows.push(
          <div className="grid grid-cols-7 my-selected-range" key={day}>
            {days}
          </div>
        );
        days = [];
      }
    }

    return <div className="bg-white p-2 parent-cls">{rows}</div>;
  };

  const handleDateClick = (date) => {
    if (isRangeMode) {
      handleRangeSelection(date);
    } else {
      handleMultiSelection(date);
    }
  };

  const handleRangeSelection = (date) => {
    if (
      internalSelectedDates.length === 0 ||
      internalSelectedDates.length === 2
    ) {
      setInternalSelectedDates([date]);
    } else {
      const [start] = internalSelectedDates;
      const range = [start, date].sort((a, b) => a - b);
      setInternalSelectedDates(range);
    }
  };

  const handleMultiSelection = (date) => {
    const isSelected = internalSelectedDates.some((d) => isSameDay(d, date));
    if (isSelected) {
      setInternalSelectedDates(
        internalSelectedDates.filter((d) => !isSameDay(d, date))
      );
    } else {
      setInternalSelectedDates([...internalSelectedDates, date]);
    }
  };

  const prevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const nextMonth = () => {
    if (!isCurrentMonthOrFuture()) {
      setCurrentDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
      );
    }
  };

  const prevYear = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1)
    );
  };

  const nextYear = () => {
    if (!isCurrentYearOrFuture()) {
      setCurrentDate(
        new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), 1)
      );
    }
  };

  const isCurrentMonthOrFuture = () => {
    const today = new Date();
    return (
      currentDate.getFullYear() > today.getFullYear() ||
      (currentDate.getFullYear() === today.getFullYear() &&
        currentDate.getMonth() >= today.getMonth())
    );
  };

  const isCurrentYearOrFuture = () => {
    return currentDate.getFullYear() >= new Date().getFullYear();
  };

  const handleRangeModeToggle = (event) => {
    const checked = event.target.checked;
    setIsRangeMode(checked);
    setinitialIsRangeMode(checked);
    if (checked) {
      // If switching to range mode, keep only the first selected date
      setInternalSelectedDates(internalSelectedDates.slice(0, 1));
    } else {
      // If switching to multi-select mode, clear selection if it was a range
      if (internalSelectedDates.length === 2) {
        setInternalSelectedDates([internalSelectedDates[0]]);
      }
    }
  };

  const resetSelection = () => {
    setInternalSelectedDates([]);
    setCurrentDate(new Date());
    onReset();
  };

  const applySelection = () => {
    if (internalSelectedDates.length > 0) {
      const selectedDatesISO = internalSelectedDates.map((date) =>
        date.toISOString()
      );
      onDateSelect(selectedDatesISO, isRangeMode);
      toggleDatePicker();
    }
  };

  return (
    <div className="w-full max-w-[320px] mx-auto">
      <div className="border rounded-lg shadow-md overflow-hidden bg-white">
        {header()}
        {daysOfWeek()}
        {cells()}
        <div className="flex justify-between items-center p-4 border-t">
          <div className="flex items-center ">
            <span className="text-sm">Custom Range</span>
            <Switch
              checked={isRangeMode}
              onChange={handleRangeModeToggle}
              inputProps={{ "aria-label": "controlled" }}
            />
            {/* <span className="text-sm">h</span> */}
          </div>
          <div className="flex gap-2">
            <button
              className="text-black font-bold flex items-center"
              onClick={resetSelection}
            >
              <span className="mr-2">🗑</span> Reset
            </button>
            <button
              className="text-orange-500 font-semibold"
              onClick={applySelection}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDaySelectCalendar;
