import React, { useState, useEffect, useRef } from 'react';
import { useCallPostMutation } from "../../services/api";
import { useSelector } from "react-redux";

// ... TableSkeleton component remains the same ...
const TableSkeleton = () => {
    const columns = 8; // 8 weeks
    const rows = 3;
    return (
        <div className="animate-pulse">
            <div className="grid grid-cols-[200px_repeat(8,1fr)] gap-4 mb-4">
                <div className="h-10 bg-gray-200 rounded"></div>
                {Array(columns).fill(null).map((_, i) => (
                    <div key={i} className="h-10 bg-gray-200 rounded"></div>
                ))}
            </div>
            {Array(rows).fill(null).map((_, i) => (
                <div key={i} className="mb-8">
                    {Array(4).fill(null).map((_, j) => (
                        <div key={j} className="grid grid-cols-[200px_repeat(8,1fr)] gap-4 mb-2">
                            <div className="h-8 bg-gray-100 rounded"></div>
                            {Array(columns).fill(null).map((_, k) => (
                                <div key={k} className="h-8 bg-gray-100 rounded"></div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
const MetricsTable = ({ currentPage, handleTotalcount, itemsPerPage }) => {
    const [locationsData, setLocationsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [pageSize, setPageSize] = useState(5);
    const [totalLocations, setTotalLocations] = useState(0);
    const [error, setError] = useState(null);
    const observerRef = useRef(null);
    const [lastElementRef, setLastElementRef] = useState(null);

    // Redux selectors
    const selectedLocations = useSelector((state) => state.filter.selectedLocations);
    const {
        weeklyDates: { start },
        addFilter: { opscore, ratings, sales, status },
        search,
    } = useSelector((state) => state.filter);

    const [postApi] = useCallPostMutation();

    const METRICS = [
        { key: 'rating', label: 'Rating' },
        { key: 'inaccurateOrders', label: 'Inaccurate orders' },
        { key: 'riderWaitingTime', label: 'Rider waiting time' },
        { key: 'openRate', label: 'Open rate' }
    ];

    const fetchData = async (pageNumber, isReset = false) => {
        try {
            setLoading(true);
            setError(null);

            const response = await postApi({
                url: "weekly-operations/v2/new-weekly-operation",
                body: {
                    page: pageNumber,
                    limit: pageSize,
                    startDate: start,
                    locations: selectedLocations
                }
            });

            if (!response.data?.status) {
                throw new Error(response.data?.message || 'Failed to fetch data');
            }

            const { locations, pagination } = response.data.data;
            setTotalLocations(pagination.totalLocations);
            handleTotalcount(response?.data?.data?.pagination?.totalLocations
                , response?.data?.data?.pagination
                    ?.totalPages, pageNumber)
            if (isReset) {
                setLocationsData(locations);
            } else {
                setLocationsData(prevData => {
                    // Get existing location names
                    const existingNames = new Set(prevData.map(loc => loc.name));

                    // Filter out any duplicate locations
                    const newLocations = locations.filter(loc => !existingNames.has(loc.name));

                    return [...prevData, ...newLocations];
                });
            }

            setHasMore(pageNumber * pageSize < pagination.totalLocations);

        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to load data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Reset everything when filters change
    useEffect(() => {
        setPage(1);
        setLocationsData([]);
        setHasMore(true);
        fetchData(1, true);
    }, [selectedLocations, pageSize, start, opscore, ratings, sales, status, search]);

    // Infinite scroll observer
    useEffect(() => {
        if (loading) return;

        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && hasMore) {
                    setPage(prevPage => prevPage + 1);
                }
            },
            { threshold: 0.1 }
        );

        if (lastElementRef) {
            observer.observe(lastElementRef);
        }

        return () => {
            if (lastElementRef) {
                observer.unobserve(lastElementRef);
            }
        };
    }, [loading, hasMore, lastElementRef]);

    // Fetch more data when page changes
    useEffect(() => {
        if (page > 1) {
            fetchData(page);
        }
    }, [page]);

    const getMetricColor = (value, metric) => {
        console.log({ value, metric })
        if (!value || value === "0.0" || value === "0.00") return 'bg-red-100 text-black';
        if (metric === "rating") {
            if (value >= 4.5 || value >= 90) return 'bg-green-100 text-black';
            else if (value >= 4 && value < 4.5 || value >= 80 && value < 90) return 'bg-blue-100 text-black';
            else if (value >= 3.5 && value < 4 || value >= 70 && value < 80) return 'bg-yellow-100 text-black';
            else if (value >= 3 && value < 3.5 || value >= 60 && value < 70) return 'bg-orange-100 text-black';
            else if (value >= 0 && value < 3 || value >= 0 && value < 60) return 'bg-red-100 text-black';
            return 'bg-red-100 text-black';
        }
        else {
            // const numValue = parseFloat(value);
            if (value >= 90) return 'bg-green-100 text-black';
            else if (value >= 80 && value < 90) return 'bg-blue-100 text-black';
            else if (value >= 70 && value < 80) return 'bg-yellow-100 text-black';
            else if (value >= 60 && value < 70) return 'bg-orange-100 text-black';
            else if (value >= 0 && value < 60) return 'bg-red-100 text-black';
            return 'bg-red-100 text-black';

        }

    };

    if (error) {
        return (
            <div className="w-full p-4 text-center">
                <div className="text-red-500">{error}</div>
                <button
                    onClick={() => fetchData(1, true)}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Retry
                </button>
            </div>
        );
    }

    if (loading && locationsData.length === 0) {
        return <TableSkeleton />;
    }

    return (
        <div className="">
            <div className="overflow-x-auto">
                <table className="min-w-full border-collapse border">

                    <tbody>
                        {locationsData.map((location, locationIndex) => (
                            <React.Fragment key={location.name}>
                                <td>  <div className="p-2  text-lg text-primary-7  font-semibold">{location.name}</div></td>
                                {METRICS.map((metric, metricIndex) => {
                                    const isLastElement =
                                        locationIndex === locationsData.length - 1 &&
                                        metricIndex === METRICS.length - 1;

                                    return (<>

                                        <tr
                                            key={`${location.name}-${metric.key}`}
                                            ref={isLastElement ? setLastElementRef : null}
                                        >

                                            <td className="border p-2 sticky left-0 bg-white z-10" style={{ width: '370px' }}>



                                                <div className="p-2 roboto-font-table font-semibold" >
                                                    {metric.label}
                                                </div>

                                            </td>
                                            {location.metrics.map((weekMetric) => (
                                                <td
                                                    style={{ width: '265px' }}
                                                    key={`${weekMetric.weekCommencing}-${metric.key}`}
                                                    className={`border p-2 text-center ${getMetricColor(weekMetric[metric.key], metric.key)}`}
                                                >
                                                    {metric.label === "Rating"
                                                        ? (weekMetric[metric.key] == 0.0 ? 0 : (weekMetric[metric.key] || 'N/A'))
                                                        : (weekMetric[metric.key] == 0.00 ? '0' : (weekMetric[metric.key] ? `${weekMetric[metric.key]}%` : 'N/A'))
                                                    }
                                                </td>


                                            ))}
                                        </tr>
                                    </>);
                                })}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>

            {loading && <div className="mt-4"><TableSkeleton /></div>}

            {!loading && !hasMore && (
                <div className="text-center text-gray-500 mt-4">
                    No more locations to load
                </div>
            )}
        </div>
    );
};

export default MetricsTable;