import React, { useState, useRef, useEffect } from "react";
import { CiCalendar } from "react-icons/ci";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { format, addDays, subDays, parseISO, isValid } from "date-fns";
import SingleDaySelectCalendar from "./SingleDaySelectCalendar";
import { useSelector } from "react-redux";

const ParentDatePicker = ({ onDateChange }) => {
  const { selectedDates: initialDate } = useSelector(
    (state) => state?.dailyOperations
  );

  const formatDate = (date) => format(date, "d MMM, yy");
  const modalRef = useRef(null);

  const [selectedDates, setSelectedDates] = useState(
    initialDate ? [parseISO(initialDate)] : [new Date()]
  );
  const [displayDate, setDisplayDate] = useState(
    initialDate ? formatDate(parseISO(initialDate)) : formatDate(new Date())
  );

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRangeMode, setIsRangeMode] = useState(false);

  useEffect(() => {
    if (initialDate) {
      const parsedDate = parseISO(initialDate);
      if (isValid(parsedDate)) {
        setSelectedDates([parsedDate]);
        setDisplayDate(formatDate(parsedDate));
      } else {
        console.error("Invalid initial date format");
      }
    }
  }, [initialDate]);

  function formatDateToYYYYMMDD(date) {
    return format(date, "yyyy-MM-dd");
  }

  const moveToPreviousDay = () => {
    if (isRangeMode) return;
    setLoading(true);
    const newDate = subDays(selectedDates[0], 1);
    updateSelectedDates([newDate]);
    setTimeout(() => setLoading(false), 1000);
  };

  const moveToNextDay = () => {
    if (isRangeMode) return;
    setLoading(true);
    const newDate = addDays(selectedDates[0], 1);
    updateSelectedDates([newDate]);
    setTimeout(() => setLoading(false), 1000);
  };

  const toggleDatePicker = () => {
    setIsDatePickerVisible(!isDatePickerVisible);
  };

  const updateSelectedDates = (dates) => {
    console.log("Updating selected dates:", dates, "isRangeMode:", isRangeMode);
    setSelectedDates(dates);
    updateDisplayDate(dates);
    onDateChange(
      dates.map(formatDateToYYYYMMDD).join(", "),
      isRangeMode,
      isDatePickerVisible
    );
  };

  const updateDisplayDate = (dates) => {
    console.log(
      "Updating display date. Dates:",
      dates,
      "isRangeMode:",
      isRangeMode
    );
    if (isRangeMode && dates.length === 2) {
      const newDisplayDate = `${formatDate(dates[0])} - ${formatDate(
        dates[1]
      )}`;
      console.log("Setting range display:", newDisplayDate);
      setDisplayDate(newDisplayDate);
    } else if (!isRangeMode && dates.length > 1) {
      const newDisplayDate = `${formatDate(dates[0])} (+${dates.length - 1})`;
      console.log("Setting multi-select display:", newDisplayDate);
      setDisplayDate(newDisplayDate);
    } else {
      const newDisplayDate = formatDate(dates[0]);
      console.log("Setting single date display:", newDisplayDate);
      setDisplayDate(newDisplayDate);
    }
  };

  const handleDateSelect = (dates, newIsRangeMode) => {
    console.log(
      "Handling date select. Dates:",
      dates,
      "newIsRangeMode:",
      newIsRangeMode
    );
    if (
      Array.isArray(dates) &&
      dates.every((date) => typeof date === "string")
    ) {
      const parsedDates = dates.map(parseISO).filter(isValid);
      if (parsedDates.length > 0) {
        setIsRangeMode(newIsRangeMode);
        updateSelectedDates(parsedDates);
        setIsDatePickerVisible(false);
      } else {
        console.error("Invalid dates selected");
      }
    } else {
      console.error("Expected dates to be an array of strings");
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsDatePickerVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const handleReset = () => {
    const currentDate = new Date();
    updateSelectedDates([currentDate]);
    setIsDatePickerVisible(false);
    setIsRangeMode(false);
  };

  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center gap-4">
        <button
          className="text-md rounded text-gray-400"
          onClick={moveToPreviousDay}
          disabled={loading || isRangeMode}
        >
          <FaChevronLeft color="#333B48" />
        </button>
        <span className="text-sm font-semibold text-primary-7">
          {loading ? "Loading..." : displayDate}
        </span>
        <button
          className="text-md rounded text-gray-400"
          onClick={moveToNextDay}
          disabled={loading || isRangeMode}
        >
          <FaChevronRight color="#333B48" />
        </button>
      </div>

      <div
        className="bg-white border-2 border-primary-5 px-2 py-2 rounded-lg flex items-center cursor-pointer w-10 mr-4 relative"
        onClick={toggleDatePicker}
      >
        <CiCalendar size={20} />
        {!isRangeMode && selectedDates.length > 1 && (
          <div className="w-4 h-4 flex items-center justify-center bg-orange-500 text-white rounded-full absolute -top-1 -right-1">
            <span className="text-[0.65rem] font-semibold">
              {selectedDates.length}
            </span>
          </div>
        )}
      </div>

      {isDatePickerVisible && (
        <div className="absolute left-100 top-36 z-50" ref={modalRef}>
          <SingleDaySelectCalendar
            onDateSelect={handleDateSelect}
            toggleDatePicker={toggleDatePicker}
            selectedDates={selectedDates}
            onReset={handleReset}
            isRangeMode={isRangeMode}
            setIsRangeMode={(checked) => {
              setIsRangeMode(checked);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ParentDatePicker;
