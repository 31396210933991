import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Line } from "react-chartjs-2";
import { Growthicon, Declineicon } from "../icons";

const NewAverageOperationalScore = ({ data = {}, weeklyLoading }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Score",
                data: [],
                borderColor: "#10B981",
                borderWidth: 1,
                pointRadius: 5,
                pointBorderWidth: 0,
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                tension: 0,
                fill: true,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0.1, "rgba(23, 224, 128, 0.2)");
                    gradient.addColorStop(0.5, "rgba(255, 255, 255, 0)");
                    return gradient;
                },
            },
        ],
    });

    useEffect(() => {
        if (data.lasteightweekscore) {
            const labels = data.lasteightweekscore.map(
                (score) => `${score.startdate} - ${score.enddate}`
            );
            const scores = data.lasteightweekscore.map((score) =>
                parseFloat(score.overallscore)
            );

            // Extend scores and labels to ensure the chart is filled
            while (scores.length < 8) {
                scores.push(scores[scores.length - 1]);
                labels.push(labels[labels.length - 1]); // Extend labels too
            }

            setChartData({
                labels: labels, // Use labels with date ranges
                datasets: [
                    {
                        label: "Score",
                        data: scores,
                        borderColor: "#10B981",
                        borderWidth: 1,
                        pointRadius: 5,
                        pointBorderWidth: 0,
                        pointBorderColor: "transparent",
                        pointBackgroundColor: "transparent",
                        tension: 0,
                        fill: true,
                        backgroundColor: (context) => {
                            const ctx = context.chart.ctx;
                            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                            gradient.addColorStop(0.1, "rgba(23, 224, 128, 0.2)");
                            gradient.addColorStop(0.5, "rgba(255, 255, 255, 0)");
                            return gradient;
                        },
                    },
                ],
            });
        }
    }, [data]);

    const totalAverage = parseFloat(data.score || 0);
    const previousWeekScore = parseFloat(data.previousweekScore || 0);
    const percentageChange = previousWeekScore
        ? (((totalAverage - previousWeekScore) / previousWeekScore) * 100).toFixed(
            2
        )
        : 0;

    const isGrowth = percentageChange >= 0;

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true, // Enable tooltips
                backgroundColor: "white", // Tooltip background color
                titleColor: "black", // Tooltip title color
                bodyColor: "black", // Tooltip body text color
                borderColor: "rgba(0, 0, 0, 0.1)", // Tooltip border color
                borderWidth: 1, // Tooltip border width
                callbacks: {
                    label: function (tooltipItem) {
                        const label = tooltipItem.dataset.label || "";
                        const value = tooltipItem.raw;
                        return `${label}: ${value}`;
                    },
                },
            },
        },
        scales: {
            y: {
                display: false, // Hide y-axis
                min: 10,
                // max: 30, // Hide y-axis
            },
            x: {
                display: false, // Hide x-axis
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
    };

    return (
        <div className="bg-white rounded-lg shadow-md flex flex-col gap-2 h-[14.2rem]">
            {weeklyLoading ? (
                <div className="px-4 pt-2 pb-0">
                    <Skeleton height={20} width={150} />
                    <Skeleton height={20} width={50} />
                    <Skeleton height={100} />
                </div>
            ) : (
                <>
                    <div className="px-4 pt-2 pb-0">
                        <p className="text-md font-medium text-primary-5">
                            Trending operational score
                        </p>
                    </div>
                    <div className="flex items-center gap-1 px-4">
                        <span className="text-xl font-bold">
                            {totalAverage.toFixed(2)}%
                        </span>
                        {isGrowth ? (
                            <>
                                <Growthicon />
                                <span className="text-green-500 font-semibold text-xs">
                                    +{percentageChange}% vs. last week
                                </span>
                            </>
                        ) : (
                            <>
                                <Declineicon />
                                <span className="text-red-500 font-semibold text-xs">
                                    {percentageChange}% vs. last week
                                </span>
                            </>
                        )}
                    </div>
                    <div className="flex flex-col space-y-2 pl-3">
                        <div>
                            <span className="">Ratings</span>
                        </div>
                        <div>
                            <span className="">Open Rate</span>
                        </div>
                        <div>
                            <span className="">Missing Item</span>
                        </div>
                        <div>
                            <span className="">Rider Waiting Time</span>
                        </div>
                        {/* <Line data={chartData} options={options} />*/}
                    </div>
                </>
            )}
        </div>
    );
};

export default NewAverageOperationalScore;
