import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Growthicon, Declineicon } from "../icons";
import { useSearchParams } from "react-router-dom";

const AverageRating = ({ data = {}, weeklyLoading }) => {
  // const [searchParams] = useSearchParams();
  // const locationRating = searchParams?.get("rating");
  // const locationRedirect = searchParams?.get("redirect");

  const platforms = ["deliveroo", "justeat", "uber"];
  const averageRatingData = platforms.map((platform) => ({
    img: `${platform}.png`,
    name: platform.charAt(0).toUpperCase() + platform.slice(1),
    ratings: parseFloat(data[platform]?.rating || 0),
    description: data[platform]?.count.toString() || "0",
  }));

  const totalRatings = averageRatingData.reduce(
    (total, item) => total + item.ratings,
    0
  );
  const totalPreviousRatings = platforms.reduce(
    (total, platform) =>
      total + parseFloat(data[platform]?.previousweekrating || 0),
    0
  );

  const averageRating = (totalRatings / 3).toFixed(1);
  const previousAverageRating = (totalPreviousRatings / 3).toFixed(1);
  const percentageChange =
    previousAverageRating > 0
      ? (
          ((averageRating - previousAverageRating) / previousAverageRating) *
          100
        ).toFixed(2)
      : averageRating > 0
      ? 100
      : 0;

  const isGrowth = percentageChange >= 0;

  const StarRating = ({ rating }) => {
    const fullStars = Math.floor(rating);
    const remainder = rating % 1;
    let halfStarOffset = 0;

    if (remainder >= 0.9) {
      halfStarOffset = 90;
    } else if (remainder >= 0.8) {
      halfStarOffset = 80;
    } else if (remainder >= 0.7) {
      halfStarOffset = 70;
    } else if (remainder >= 0.6) {
      halfStarOffset = 60;
    } else if (remainder >= 0.5) {
      halfStarOffset = 50;
    } else if (remainder >= 0.4) {
      halfStarOffset = 40;
    } else if (remainder >= 0.3) {
      halfStarOffset = 30;
    } else if (remainder >= 0.2) {
      halfStarOffset = 20;
    } else if (remainder >= 0.1) {
      halfStarOffset = 10;
    } else if (remainder >= 0) {
      halfStarOffset = 0;
    }

    return (
      <div className="flex items-center">
        {[...Array(5)].map((_, index) => {
          if (index < fullStars) {
            return (
              <svg
                key={index}
                className="w-4 h-4 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 15l-5.878 3.09 1.122-6.545L.488 6.91l6.564-.955L10 0l2.948 5.955 6.564.955-4.756 4.635 1.122 6.545z" />
              </svg>
            );
          } else if (index === fullStars && halfStarOffset > 0) {
            return (
              <svg
                key={index}
                className="w-4 h-4 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <defs>
                  <linearGradient id="halfStarGradient">
                    <stop offset={`${halfStarOffset}%`} stopColor="#FACC15" />
                    <stop offset={`${halfStarOffset}%`} stopColor="#D1D5DB" />
                  </linearGradient>
                </defs>
                <path
                  fill="url(#halfStarGradient)"
                  d="M10 15l-5.878 3.09 1.122-6.545L.488 6.91l6.564-.955L10 0l2.948 5.955 6.564.955-4.756 4.635 1.122 6.545z"
                />
              </svg>
            );
          } else {
            return (
              <svg
                key={index}
                className="w-4 h-4 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 15l-5.878 3.09 1.122-6.545L.488 6.91l6.564-.955L10 0l2.948 5.955 6.564.955-4.756 4.635 1.122 6.545z" />
              </svg>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div className="bg-white px-4 py-2 rounded-lg shadow-md h-[14.2rem]">
      {weeklyLoading ? (
        <div className="flex items-center space-x-2 mb-6">
          <Skeleton width={40} height={24} />
          <Skeleton width={100} height={10} />
        </div>
      ) : (
        <>
          <h3 className="text-md font-medium text-primary-5">
            Average Weekly Rating
          </h3>
          <div className="flex items-center space-x-2 mb-[1rem] mt-[6px]">
            <p className="text-xl font-bold">{data?.overAllRating?.rating}</p>

            <svg
              className="w-4 h-4 text-yellow-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 15l-5.878 3.09 1.122-6.545L.488 6.91l6.564-.955L10 0l2.948 5.955 6.564.955-4.756 4.635 1.122 6.545z" />
            </svg>
            <span
              className={`font-semibold flex text-xs gap-1 ${
                isGrowth ? "text-green-500" : "text-red-500"
              }`}
            >
              {isGrowth ? (
                <Growthicon className="text-green-500" />
              ) : (
                <Declineicon className="text-red-500" />
              )}
              {isGrowth ? "+" : ""}
              {percentageChange}% vs. last week
            </span>
          </div>
        </>
      )}
      <div className="space-y-3">
        {weeklyLoading ? (
          <>
            {[...Array(3)].map((_, index) => (
              <div key={index} className="flex items-center">
                <Skeleton
                  circle={true}
                  width={20}
                  height={20}
                  className="mr-4"
                />
                <Skeleton width={100} />
                <div className="flex items-center ml-auto">
                  <Skeleton width={30} height={24} className="mr-2" />
                  {[...Array(5)].map((_, i) => (
                    <Skeleton key={i} width={20} height={20} className="mr-1" />
                  ))}
                  <Skeleton width={30} height={24} className="ml-2" />
                </div>
              </div>
            ))}
          </>
        ) : (
          averageRatingData.map((item, index) => (
            <div key={index} className="flex items-center">
              <img
                src={`./${item?.img}`}
                alt={item?.name}
                className="w-8 h-8 mr-2"
              />
              <span className="text-sm flex-1" style={{ fontWeight: "600" }}>
                {item.name}
              </span>
              <div className="flex items-center justify-between gap-x-2">
                <span className="text-sm font-bold text-gray-900">
                  {item.ratings.toFixed(1)}
                </span>
                <StarRating rating={item?.ratings} />
                <span className="text-gray-500 text-sm w-5 text-right">
                  {item?.description}
                </span>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AverageRating;
