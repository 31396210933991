import React from "react";
import { PiCalculatorBold } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import { MarketingSvg } from "../icons";
import { useLocation } from "react-router-dom";
import decodeToken from "../../utils/decodeToken";

const SidebarItems = () => {
  const location = useLocation();

  const marketingRoute = "/marketing";
  const colorCode = "#071B32";


  const token = localStorage.getItem("token");
  const userdata = decodeToken(token);

  const items2 = [{
    icon: <PiCalculatorBold />,
    name: "Overview",
    link: "/cf-weekly-operations",
  }, {
    icon: <PiCalculatorBold />,
    name: "Sales and Operations",
    link: "/new-weekly-operations",
  }];

  if (userdata?.user_id === 162) {
    return items2;
  }
  const items = [
    {
      icon: <IoLocationOutline />,
      name: "Locations",
      link: "/locations",
    },
    {
      icon: <PiCalculatorBold />,
      name: "Daily operations",
      link: "/daily-operations",
    },
    {
      icon: <PiCalculatorBold />,
      name: "Sales and Operations",
      link: "/weekly-operations",
    },
    {
      icon: <PiCalculatorBold />,
      name: "Coqfighter Report",
      link: "/cf-weekly-operations",
    },
    {
      icon: <PiCalculatorBold />,
      name: "Coqfighter Sales and Operations",
      link: "/new-weekly-operations",
    },
    // cf-weekly-operations
    {
      icon:
        location.pathname === marketingRoute ? (
          <MarketingSvg color={colorCode} />
        ) : (
          <MarketingSvg />
        ),
      name: "Marketing",
      link: marketingRoute,
    },
  ];
  return items;
};

export default SidebarItems;
