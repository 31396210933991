import React, { useCallback, useEffect, useState } from 'react'
import AddFilters from './AddFilters';
import Pagination from './Pagination';
import SearchBar from './Searchbar';
import SaleSiteTable from './SaleSiteTable';
import { setFilterItemPerPage } from '../../redux/slices/filter-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useCallPostMutation } from '../../services/api';
import InfiniteScrollTable from './InfiniteScrollTable';
import MetricsTable from './MetricsTable';


function DailyOperationsTable({ locations }) {
    console.log({ locations })
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(3);
    const [locationData, setLocationData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([])
    const [totalCount, settotalCount] = useState()
    const [totalPages, setTotalPages] = useState()
    const [pageNumber, setPageNumber] = useState()
    const locationDate = useSelector((state) => state?.filter?.weeklyDates);
    console.log({ locationDate })
    const dispatch = useDispatch()
    const [postApi] = useCallPostMutation();
    const ReduxSelectedLocations = useSelector(
        (state) => state.filter.selectedLocations
    );
    console.log({ ReduxSelectedLocations })
    const {
        weeklyDates: { start, end } = {},
        addFilter: { opscore, ratings, sales, status } = {},
        appliedFilterCount,
        search = "",
        totalpages = 0,
    } = useSelector((state) => state.filter) || {};
    const handleTotalCount = useCallback(async (count, pages, pageNumber) => {
        console.log({ count, pages, pageNumber })
        try {

            await Promise.resolve();
            settotalCount(count);
            setTotalPages(pages);
            setPageNumber(pageNumber)
            console.log('States updated:', { count, pages });
        } catch (error) {
            console.error('Error updating states:', error);
        }
    }, []);


    const fetchData = async (pageNumber) => {
        try {
            setLoading(true);
            const payload = {
                startDate: start,
                endDate: end,
                locations: ReduxSelectedLocations,
                page: currentPage,
                pageSize: itemsPerPage,


            };
            console.log({ payload })
            const response = await postApi({
                url: "weekly-operations/all-sites-count",
                body: payload,
            });
            console.log("response1234", response?.data?.data)
            setData(response?.data?.data)


        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);

        }
    };

    useEffect(() => {
        fetchData(1);
    }, [start,
        end,
        search,
        postApi,
        currentPage,
        itemsPerPage,
        totalpages,
        dispatch,
        appliedFilterCount,
        ReduxSelectedLocations,]);

    // const totalCount = 208
    const totalpage = 3;
    const itemspage = 3

    const pageCountArray = [0];

    for (let i = itemspage; i <= totalCount + itemspage; i += itemspage) {
        pageCountArray.push(i);
    }

    const handleItemsPerPageChange = (newItemsPerPage) => {
        console.log({ newItemsPerPage })
        setItemsPerPage(newItemsPerPage);

        dispatch(setFilterItemPerPage(newItemsPerPage));
        setCurrentPage(1);
    };
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const Label = ({ color, text }) => (
        <div className="flex items-center space-x-2">
            <span
                className={`w-[0.55rem] h-[0.55rem] rounded-full`}
                style={{ backgroundColor: color }}
            ></span>
            <span className="text-gray-700 text-sm">{text}</span>
        </div>
    );
    console.log({ data })
    return (
        <>
            <div className="h-[calc(100vh-64px)] flex flex-col">
                {/* Sticky Header Section */}
                <div className="sticky top-0 bg-white z-30 shadow-sm">
                    {/* Operations Header */}
                    <div className="flex flex-col sm:flex-row items-center justify-between mt-2 gap-4">
                        <div className="flex flex-col flex-grow">
                            <span className="text-lg text-primary-7 font-semibold">Last 8 Weeks Operations</span>
                        </div>
                        <div
                            className="flex flex-col sm:flex-row items-center gap-4 w-full sm:w-auto flex-wrap"
                            style={{
                                overflowY: "auto",
                                overflowX: "hidden",
                                maxHeight: "400px",
                                padding: "8px",
                                scrollbarWidth: "none", // Firefox
                                msOverflowStyle: "none", // IE and Edge
                            }}
                        >
                            <AddFilters />
                            <Pagination
                                totalPages={totalPages}
                                itemsPerPage={itemsPerPage}
                                currentPage={pageNumber > currentPage ? pageNumber : currentPage}
                                onPageChange={handlePageChange}
                                onItemsPerPageChange={handleItemsPerPageChange}
                                pageCountArray={pageCountArray}
                                totalCounts={totalCount}
                            />
                        </div>
                    </div>

                    {/* Labels */}
                    <div className="flex space-x-6 pt-4 pb-4">
                        <Label color="#008000" text="Excellent" />
                        <Label color="#56bcf9" text="Good" />
                        <Label color="#FFFF00" text="Attention Required" />
                        <Label color="#FFA500" text="At risk" />
                        <Label color="#FF0000" text="Critical" />
                    </div>

                    {/* Table Header */}
                    <div className="overflow-x-auto">
                        <table className="min-w-full border-collapse rounded-lg border">
                            <thead>
                                <tr>
                                    <th className="p-2 border bg-primary-2 roboto-font-table">Week Commencing</th>
                                    {data?.toReversed()?.map((week, index) => (
                                        <th key={index} className="border bg-primary-2 p-2 z-10">
                                            {week.weekCommencing}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full border-collapse rounded-lg border">
                        <tbody>
                            <tr>
                                <td className="p-2 border roboto-font-table font-semibold" style={{ width: '265px' }}>Total sites</td>
                                {data?.toReversed()?.map((row, index) => (
                                    <td key={index} className="p-2 border text-center">
                                        {row.totalSites}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td className="p-2 border roboto-font-table font-semibold" style={{ width: '265px' }}>Site visit triggers</td>
                                {data?.toReversed()?.map((row, index) => (
                                    <td key={index} className="p-2 border text-center">
                                        {row.siteVisitTriggers}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* Scrollable Content Section */}
                <div
                    className="overflow-y-auto flex-1"
                    style={{
                        overflowY: "auto",
                        overflowX: "hidden",
                        scrollbarWidth: "none", // Firefox
                        msOverflowStyle: "none", // IE and Edge
                    }}
                >
                    {/* Table Body */}


                    {/* Metrics Table */}
                    <MetricsTable itemsPerPage={itemsPerPage} handleTotalcount={handleTotalCount} currentPage={currentPage} />
                </div>
            </div>
        </>


    )
}

export default DailyOperationsTable
