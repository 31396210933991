import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  OverlayView
} from "@react-google-maps/api";

import HoverTooltip from "./HoverTooltip";
import ClickModal from "./ClickModal";
import MapControls from "./MapControls";
import { createCustomMarker } from "./utils";
import { 
  DEFAULT_CENTER, 
  DEFAULT_ZOOM, 
  mapContainerStyle, 
  customMapStyles 
} from "./constants";
import { infoWindowStyles } from "./styles";

const MainGoogleMap = () => {
  const markerPositions = useSelector((state) => state.filter.markerPositions);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [mapError, setMapError] = useState(null);
  const [map, setMap] = useState(null);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = infoWindowStyles;
    document.head.appendChild(styleElement);

    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      styleElement.remove();
      if (map) {
        setMap(null);
      }
    };
  }, [map]);

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      mapContainerRef.current?.requestFullscreen().catch((err) => {
        console.error("Error enabling full-screen mode:", err);
        setMapError("Failed to enter fullscreen mode");
      });
    } else {
      document.exitFullscreen();
    }
  }, []);

  const handleZoomIn = useCallback(() => {
    if (map) {
      map.setZoom(map.getZoom() + 1);
    }
  }, [map]);

  const handleZoomOut = useCallback(() => {
    if (map) {
      map.setZoom(map.getZoom() - 1);
    }
  }, [map]);

  const handleMarkerMouseOver = useCallback((marker) => {
    if (!activeMarker) {
      setHoveredMarker(marker);
    }
  }, [activeMarker]);

  const handleMarkerMouseOut = useCallback(() => {
    setHoveredMarker(null);
  }, []);

  const handleMarkerClick = useCallback((marker) => {
    setHoveredMarker(null);
    setActiveMarker(activeMarker?.lat === marker.lat ? null : marker);
  }, [activeMarker]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  if (mapError) {
    return <div className="text-red-500">Error loading map: {mapError}</div>;
  }

  const center = markerPositions[0] || DEFAULT_CENTER;

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    styles: customMapStyles,
    backgroundColor: '#fff',
    clickableIcons: false,
    gestureHandling: 'greedy',
    minZoom: 3,
    maxZoom: 18,
  };

  return (
    <div ref={mapContainerRef} className="w-full shadow-md h-full relative">
      <LoadScript googleMapsApiKey="AIzaSyAravivpxM8-uc_PTmb2reJ4bwGCc6J3Ik">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={DEFAULT_ZOOM}
          options={mapOptions}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {markerPositions?.map((position, index) => (
            <React.Fragment key={`marker-${position.lat}-${position.lng}-${index}`}>
              <Marker
                position={{ lat: position.lat, lng: position.lng }}
                onClick={() => handleMarkerClick(position)}
                onMouseOver={() => handleMarkerMouseOver(position)}
                onMouseOut={handleMarkerMouseOut}
                icon={createCustomMarker(position.rating)}
              />
              
              {hoveredMarker?.lat === position.lat && !activeMarker && (
                <OverlayView
                  position={{ lat: position.lat, lng: position.lng }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -height - 40,
                  })}
                >
                  <HoverTooltip
                    location={position.location}
                    rating={position.rating}
                  />
                </OverlayView>
              )}

              {activeMarker?.lat === position.lat && (
                <InfoWindow
                  position={{ lat: position.lat, lng: position.lng }}
                  onCloseClick={() => setActiveMarker(null)}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -30),
                    disableAutoPan: false,
                  }}
                >
                  <ClickModal
                    location={activeMarker.location}
                    rating={activeMarker.rating}
                    opScore={activeMarker.opScore}
                  />
                </InfoWindow>
              )}
            </React.Fragment>
          ))}

          <MapControls
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
            onFullscreen={toggleFullscreen}
            isFullscreen={isFullscreen}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MainGoogleMap;